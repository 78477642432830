/* Base styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: #151515;
    color: #ffffff;
    overflow-x: hidden;
}

/* Enhanced breathing light effect */
.breathing-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1 !important;
    pointer-events: none !important;
    overflow: hidden;
}

/* First glow */
.breathing-background::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
        radial-gradient(
            circle at 30% 30%,
            rgba(97, 218, 251, 0.4) 0%,
            rgba(97, 218, 251, 0.2) 25%,
            rgba(97, 218, 251, 0.1) 35%,
            transparent 70%
        ),
        radial-gradient(
            circle at 70% 70%,
            rgba(72, 168, 194, 0.4) 0%,
            rgba(72, 168, 194, 0.2) 25%,
            rgba(72, 168, 194, 0.1) 35%,
            transparent 70%
        );
    animation: glow 5s ease-in-out infinite;
    transform-origin: center;
}

/* Second glow (additional glow layer) */
.breathing-background::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
        radial-gradient(
            circle at 60% 40%,
            rgba(97, 218, 251, 0.3) 0%,
            rgba(97, 218, 251, 0.1) 30%,
            transparent 65%
        );
    animation: glow2 6s ease-in-out infinite;
    transform-origin: center;
}

@keyframes glow {
    0%, 100% {
        opacity: 0.5;
        transform: scale(0.95) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: scale(1.05) rotate(2deg);
    }
}

@keyframes glow2 {
    0%, 100% {
        opacity: 0.3;
        transform: scale(1) rotate(0deg);
    }
    50% {
        opacity: 0.7;
        transform: scale(1.1) rotate(-2deg);
    }
}

/* Content container */
.app-container {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

/* Navigation bar styles */
.navbar {
    position: fixed !important;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(21, 21, 21, 0.7);
    backdrop-filter: blur(10px);
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 9999 !important;
    width: auto;
    max-width: 80%;
    pointer-events: all !important;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 10px;
    position: relative;
    z-index: inherit;
    pointer-events: all !important;
}

.navbar li {
    padding: 8px 16px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 15px;
    font-size: 0.95em;
    user-select: none;
    position: relative;
    z-index: inherit;
    pointer-events: all !important;
}

.navbar li:hover {
    color: #61dafb;
    background: rgba(97, 218, 251, 0.1);
}

.navbar li.active {
    color: #61dafb;
    background: rgba(97, 218, 251, 0.2);
    box-shadow: 0 2px 10px rgba(97, 218, 251, 0.3);
}

/* Remove all possible interference styles */
.breathing-background {
    pointer-events: none;
    z-index: -1;
}

.app-container {
    position: relative;
    z-index: 1;
}

/* Ensure content area is positioned correctly */
section {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0 20px;
}

/* Page layout adjustment */
.banner {
    min-height: 100vh;
    padding-top: 80px;
    margin-top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

/* Adjust content container styles */
.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .banner {
        min-height: 75vh;
    }

    section {
        min-height: 70vh;
        padding: 0 15px;
    }

    .content {
        padding: 15px;
    }
}

/* Button styles */
.btn {
    padding: 10px 20px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    pointer-events: auto;
    z-index: 10;
}

/* Remove the previous ::before pseudo-element */
.btn::before {
    display: none; /* Remove the ellipse background */
}

/* Simplify hover effect */
.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.3);
    border-color: rgba(97, 218, 251, 0.5);
    background: rgba(97, 218, 251, 0.2); /* More gentle background color */
}

.btn:active {
    transform: translateY(-1px);
}

/* Download CV button special style */
.btn.download {
    background: rgba(97, 218, 251, 0.15);
    border-color: rgba(97, 218, 251, 0.3);
}

.btn.download:hover {
    background: rgba(97, 218, 251, 0.25);
}

/* Social media icons */
.icon {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    padding: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
}

.icon:hover {
    color: #61dafb;
    background: rgba(97, 218, 251, 0.1);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.2);
}

/* Button container style optimization */
.buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

/* Add social link styles */
.social-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
}

/* Ensure navigation items are clickable */
.navbar li:active {
    transform: translateY(1px);
}

/* Ensure all interactive elements have the correct z-index */
.navbar,
.btn,
.social-link,
section {
    position: relative;
    z-index: 2;
}

/* Remove styles that might interfere with clicks from other elements */
.breathing-background::before,
.breathing-background::after {
    pointer-events: none !important;
    z-index: -1;
}

/* Adjust title and paragraph spacing */
h1 {
    margin: 0 0 20px 0;
}

h2 {
    margin: 0 0 15px 0;
}

p {
    margin: 0 0 15px 0;
}

/* About Me section styles */
#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 20px;
    min-height: 70vh;
}

#about h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #ffffff;
}

#about p {
    max-width: 800px; /* Control paragraph width */
    margin: 0 auto;
    line-height: 1.8;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    white-space: normal; /* Ensure text wraps correctly */
    word-wrap: break-word; /* Handle long words wrapping */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    #about {
        padding: 40px 15px;
        min-height: 65vh;
    }

    #about p {
        font-size: 1.1em;
        padding: 0 10px;
    }
}

/* Skills section styles */
.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    width: 95%;
    margin: 15px auto;
    padding: 10px;
}

.skill-item {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(175, 217, 229, 0.2);
    border-radius: 12px;
    padding: 15px 10px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.skill-item:hover {
    transform: translateY(-5px);
    background: rgba(97, 218, 251, 0.1);
    border-color: rgba(97, 218, 251, 0.5);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.2);
}

.skill-icon {
    font-size: 2em;
    color: #61dafb;
    margin-bottom: 10px;
}

.skill-name {
    font-size: 1em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}

/* Skills category */
.skills-section {
    margin-bottom: 30px;
    width: 100%;
    max-width: 1100px;
}

.skills-section h2 {
    color: #61dafb;
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: center;
}

/* Skills section styles */
@media (max-width: 768px) {
    .skills-container {
        grid-template-columns: repeat(3, 1fr); /* Show 3 items per row */
        gap: 10px;
        padding: 5px;
        width: 100%;
    }

    .skill-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2px 2px;
        min-height: 100px;
        font-size: 0.9em;
    }

    .skill-icon {
        font-size: 3.5em;
        margin-bottom: 10px;
        color: #61dafb;
    }

    .skill-name {
        font-size: 1.4em;
        margin-top: 0px;
        font-weight: 500;
    }

    .skill-details {
        display: none; /* Hide detailed description on mobile */
    }

    /* Adjust skills category title */
    .skills-section {
        margin-bottom: 20px;
    }

    .skills-section h2 {
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    /* Show 2 items per row on smaller screens */
    @media (max-width: 480px) {
        .skills-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

/* Projects section styles */
.projects-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1200px;
    width: 90%;
    margin: 10px auto;
    padding: 20px;
}

.project-card {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(97, 218, 251, 0.2);
    border-color: rgba(97, 218, 251, 0.3);
}

.project-content {
    flex: 1;
    padding: 30px;
}

.project-content h2 {
    color: #ffffff;
    font-size: 1.8em;
    margin-bottom: 15px;
}

.project-content p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.project-image {
    flex: 1;
    min-height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.tech-tag {
    background: rgba(97, 218, 251, 0.1);
    color: #61dafb;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9em;
    border: 1px solid rgba(97, 218, 251, 0.3);
    transition: all 0.3s ease;
}

.tech-tag:hover {
    background: rgba(97, 218, 251, 0.2);
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .project-card {
        flex-direction: column;
    }

    .project-content {
        padding: 20px;
    }

    .project-image {
        min-height: 200px;
    }

    .project-content h2 {
        font-size: 1.5em;
    }

    .project-content p {
        font-size: 1em;
    }
}

/* Contact Me section styles */
#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

#contact h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffffff;
}

#contact p {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9);
}

#contact a {
    color: #61dafb;
    text-decoration: none;
    transition: all 0.3s ease;
}

#contact a:hover {
    text-decoration: underline;
}

/* Contact form styles */
.contact-form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.05);
    padding: 40px;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: white;
    font-size: 1em;
    transition: all 0.3s ease;
}

.form-input:focus {
    outline: none;
    border-color: #61dafb;
    background: rgba(255, 255, 255, 0.15);
}

.form-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.form-input.message {
    min-height: 150px;
    resize: vertical;
}

/* Submit button container */
.submit-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

/* Submit button styles */
.submit-btn {
    background: rgba(97, 218, 251, 0.2);
    color: white;
    padding: 12px 30px;
    border: 1px solid rgba(97, 218, 251, 0.3);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1em;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.submit-btn:hover {
    background: rgba(97, 218, 251, 0.3);
    transform: translateY(-2px);
}

.submit-btn:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
    transform: none;
}

.submit-btn i {
    font-size: 1.1em;
}

/* Status message styles */
.success-message,
.error-message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.success-message {
    color: #4CAF50;
    background: rgba(76, 175, 80, 0.1);
}

.error-message {
    color: #f44336;
    background: rgba(244, 67, 54, 0.1);
}

/* Responsive adjustments */
/* Contact Me mobile styles adjustment */
@media (max-width: 768px) {
    #contact {
        padding: 15px 15px;
    }

    #contact h1 {
        font-size: 1.8em;
        margin-bottom: 15px;
    }

    #contact p {
        font-size: 0.9em;
        padding: 0 10px;
        margin-bottom: 20px;
        text-align: center;
    }

    .contact-form {
        width: 90%;
        max-width: 400px;
        padding: 15px;
        margin: 0 auto;
    }

    .form-input {
        width: 100%;
        box-sizing: border-box; /* Ensure padding does not increase total width */
        padding: 12px;
        margin-bottom: 15px;
        border-radius: 8px;
        font-size: 0.9em;
    }

    .form-input.message {
        min-height: 120px;
        resize: vertical;
    }

    /* Submit button styles adjustment */
    .submit-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    /* Submit button */
    .submit-btn {
        padding: 10px 30px;
        font-size: 1em;
    }

    /* Adjust status message styles */
    .success-message,
    .error-message {
        font-size: 0.85em;
        padding: 8px;
        margin-top: 10px;
    }
}

/* Additional adjustments for smaller screens */
@media (max-width: 480px) {
    .contact-form {
        width: 95%;
        padding: 10px;
    }

    #contact p {
        font-size: 0.85em;
    }
}

/* Footer styles */
.footer {
    width: 100%;
    padding: 30px 0;
    background: rgba(21, 21, 21, 0.7);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 50px;
}

.footer-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.footer-text {
    text-align: center;
    width: 100%;
}

.copyright {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95em;
    margin-bottom: 8px;
}

.about-website {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.85em;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer {
        padding: 20px 0;
    }
    
    .footer-content {
        padding: 0 15px;
    }
    
    .copyright, 
    .about-website {
        font-size: 0.8em;
    }
}

/* Timeline styles */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

/* Vertical line in the middle */
.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background: rgba(97, 218, 251, 0.3);
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 50px 0;
}

/* Timeline content container */
.timeline-content {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 25px;
    width: calc(50% - 50px);
    position: relative;
    transition: all 0.3s ease;
}

.timeline-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.2);
    border-color: rgba(97, 218, 251, 0.3);
}

/* Left side content */
.timeline-content.left {
    margin-right: 50px;
    text-align: right;
}

/* Right side content */
.timeline-content.right {
    margin-left: 50px;
    text-align: left;
}

/* Timeline marker point */
.timeline-marker {
    width: 40px;
    height: 40px;
    background: rgba(97, 218, 251, 0.2);
    border: 3px solid rgba(97, 218, 251, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}

.timeline-marker i {
    color: #61dafb;
    font-size: 1.2em;
}

/* Content styles */
.timeline-content h3 {
    color: #61dafb;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.timeline-content h4 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1em;
    margin-bottom: 10px;
}

.timeline-content p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Date label */
.timeline-date {
    display: inline-block;
    background: rgba(97, 218, 251, 0.1);
    color: #61dafb;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9em;
    border: 1px solid rgba(97, 218, 251, 0.3);
}

/* Add year marker styles */
.timeline-year {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(97, 218, 251, 0.15);
    color: #61dafb;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 1.2em;
    border: 1px solid rgba(97, 218, 251, 0.3);
    margin-top: -50px;
    z-index: 2;
    backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
    /* Remove timeline and marker */
    .timeline::after,
    .timeline-marker,
    .timeline-year {
        display: none;
    }

    /* Reset container styles */
    .timeline {
        padding: 20px 10px;
    }

    /* Adjust each experience item styles */
    .timeline-item {
        margin: 0 auto 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Adjust left content styles - only hide empty left content */
    .timeline-content.left:empty,
    .timeline-content.left[style*="visibility: hidden"] {
        display: none;
    }

    /* Keep left part with content */
    .timeline-content.left:not(:empty) {
        width: 100%;
        margin: 0;
        padding: 20px;
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
    }

    .timeline-content.right:empty,
    .timeline-content.right[style*="visibility: hidden"] {
        display: none;
    }

    /* Right content styles */
    .timeline-content.right:not(:empty) {
        width: 100%;
        margin: 0;
        padding: 20px;
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
    }

    /* Content text styles */
    .timeline-content h3 {
        color: #61dafb;
        font-size: 1.3em;
        margin-bottom: 8px;
    }

    .timeline-content h4 {
        color: #ffffff;
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .timeline-content p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 0.95em;
        line-height: 1.5;
        margin-bottom: 12px;
    }

    /* Date styles */
    .timeline-date {
        display: inline-block;
        padding: 5px 12px;
        background: rgba(97, 218, 251, 0.1);
        border-radius: 15px;
        font-size: 0.9em;
    }
}

/* Banner layout styles */
.banner .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 20px;
    gap: 50px;
}

.banner-left {
    flex: 1;
    text-align: left;
}

.banner-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-photo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 3px solid rgba(97, 218, 251, 0.3);
    box-shadow: 0 0 20px rgba(97, 218, 251, 0.2);
    object-fit: cover;
    object-position: 50% 50%;
    transition: all 0.3s ease;
    transform: scale(0.9);
    transform-origin: center center;
}

.profile-photo:hover {
    transform: scale(1.1);
    border-color: rgba(39, 208, 255, 0.283);
    box-shadow: 0 0 30px rgba(97, 218, 251, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .banner .content {
        flex-direction: column;
        text-align: center;
        padding: 40px 20px;
        gap: 20px;
    }

    .banner-main {
        flex-direction: column;
        gap: 30px;
    }

    .banner-text {
        text-align: center;
        order: 2;
    }

    .banner-right {
        order: 1;
    }

    .profile-photo {
        width: 180px;
        height: 180px;
        margin: 0 auto;
    }

    .banner-text h1 {
        font-size: 1.8em;
        margin-bottom: 15px;
    }

    .banner-text h2 {
        font-size: 1em;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .buttons {
        justify-content: center;
        gap: 10px;
    }
}

/* Banner content styles */
.banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 180px; /* Maintain larger spacing */
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
}

.banner-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
}

.about-section {
    max-width: 950px; /* Increase max width */
    margin: 0 auto;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 5px;
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
}

.about-title {
    color: #c5f3ffe3;
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
}

.about-description {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 1.3em !important;
    line-height: 1.8;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    max-width: 950px; /* Keep consistent with about-section */
    font-family: 'Roboto', sans-serif;
    padding: 0 30px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .banner-content {
        gap: 80px;
    }

    .about-section {
        padding-top: 40px;
        margin-top: 40px;
    }

    .about-description {
        font-size: 1.1em !important;
        padding: 0 15px;
    }
}

/* Banner text styles */
.banner-text {
    text-align: left;
    flex: 1;
    max-width: 600px;
}

.banner-text h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffffff;
}

.banner-text h2 {
    font-size: 1.3em;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 25px;
}

.banner-text .highlight {
    color: #61dafb;
    font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .banner-text {
        text-align: center;
    }

    .banner-text h1 {
        font-size: 2em;
    }

    .banner-text h2 {
        font-size: 1.2em;
    }
}

/* Add to existing Skills styles */
.skill-details {
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    line-height: 1.4;
}

.skill-item {
    /* padding: 25px 20px; */
    min-height: 120px;
}

.skills-section {
    margin-bottom: 40px;
}

.skills-section h2 {
    color: #61dafb;
    font-size: 1.6em;
    margin-bottom: 15px;
}

/* Uniform styles for all main titles */
section h1 {
    font-size: 2.5em; /* Same size as Contact Me */
    margin-bottom: 30px;
    color: #ffffff;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    section h1 {
        font-size: 2em;
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
    }
    .banner-text {
        font-size: 1.5rem;
    }
}

/* New navigation toggle button styles */
.nav-toggle {
    display: none; /* Default hidden */
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
}

/* 768px */
@media (max-width: 768px) {
    .nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
        flex-direction: column;
        width: 100%;
        background: rgba(21, 21, 21, 0.7);
        position: absolute;
        top: 60px; 
        left: 0;
        z-index: 9999;
    }

    .navbar.open ul {
        display: flex;
    }
}

/* Mobile font size adjustments */
@media (max-width: 768px) {
    body {
        font-size: calc(1rem * 0.67); /* Set base font size for mobile to 2/3 of original */
    }

    h1 {
        font-size: calc(1.6em * 0.67); /* Decrease font size for h1 to 2/3 of original */
    }

    h2 {
        font-size: calc(1.6em * 0.67); /* Decrease font size for h2 to 2/3 of original */
    }

    h3 {
        font-size: calc(1.4em * 0.67); /* Decrease font size for h3 to 2/3 of original */
    }

    p {
        font-size: calc(1em * 0.67); /* Decrease font size for paragraphs to 2/3 of original */
    }

    /* Adjust font size for other elements as needed */
    .navbar li {
        font-size: calc(0.95em * 0.67); /* Decrease font size for navbar items to 2/3 of original */
    }

    .btn {
        font-size: calc(1em * 0.67); /* Decrease font size for buttons to 2/3 of original */
    }

    /* Add more styles as needed */
}

/* Work Experience section styles */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

/* Responsive adjustments for Work Experience */
@media (max-width: 768px) {
    .timeline {
        padding: 0 10px; /* Reduce padding */
    }

    .timeline-item {
        flex-direction: column; /* Stack items vertically */
        margin: 20px 0; /* Reduce margin between items */
    }

    .timeline-content {
        width: 100%; /* Full width for mobile */
        padding: 15px; /* Add some padding */
    }

    .timeline-marker {
        margin-bottom: 10px; /* Add space below marker */
    }

    .timeline-year {
        display: none; /* Optionally hide year labels for mobile */
    }
}

/* Contact Form mobile styles adjustment */
@media (max-width: 768px) {
    .contact-form {
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
        padding: 15px;
    }

    /* Adjust input field styles */
    .form-input {
        width: 100%;
        box-sizing: border-box; /* Ensure padding does not increase total width */
        padding: 12px;
        margin-bottom: 15px;
        border-radius: 8px;
        font-size: 0.9em;
    }

    /* Message box styles */
    .form-input.message {
        min-height: 120px;
        resize: vertical;
    }

    /* Submit button container */
    .submit-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    /* Submit button */
    .submit-btn {
        padding: 10px 30px;
        font-size: 1em;
    }

    /* Adjust text description styles */
    #contact p {
        padding: 0 20px;
        margin-bottom: 25px;
        font-size: 0.9em;
        text-align: center;
    }
}

/* Additional adjustments for smaller screens */
@media (max-width: 480px) {
    .contact-form {
        width: 95%;
        padding: 10px;
    }

    #contact p {
        font-size: 0.85em;
    }
}

/* Footer styles */
.footer {
    width: 100%;
    padding: 30px 0;
    background: rgba(21, 21, 21, 0.7);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 50px;
}

.footer-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.footer-text {
    text-align: center;
    width: 100%;
}

.copyright {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95em;
    margin-bottom: 8px;
}

.about-website {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.85em;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer {
        padding: 20px 0;
    }
    
    .footer-content {
        padding: 0 15px;
    }
    
    .copyright, 
    .about-website {
        font-size: 0.8em;
    }
}

/* Timeline styles */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

/* Vertical line in the middle */
.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background: rgba(97, 218, 251, 0.3);
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 50px 0;
}

/* Timeline content container */
.timeline-content {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 25px;
    width: calc(50% - 50px);
    position: relative;
    transition: all 0.3s ease;
}

.timeline-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.2);
    border-color: rgba(97, 218, 251, 0.3);
}

/* Left side content */
.timeline-content.left {
    margin-right: 50px;
    text-align: right;
}

/* Right side content */
.timeline-content.right {
    margin-left: 50px;
    text-align: left;
}

/* Timeline marker point */
.timeline-marker {
    width: 40px;
    height: 40px;
    background: rgba(97, 218, 251, 0.2);
    border: 3px solid rgba(97, 218, 251, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}

.timeline-marker i {
    color: #61dafb;
    font-size: 1.2em;
}

/* Content styles */
.timeline-content h3 {
    color: #61dafb;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.timeline-content h4 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1em;
    margin-bottom: 10px;
}

.timeline-content p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Date label */
.timeline-date {
    display: inline-block;
    background: rgba(97, 218, 251, 0.1);
    color: #61dafb;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9em;
    border: 1px solid rgba(97, 218, 251, 0.3);
}

/* Add year marker styles */
.timeline-year {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(97, 218, 251, 0.15);
    color: #61dafb;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 1.2em;
    border: 1px solid rgba(97, 218, 251, 0.3);
    margin-top: -50px;
    z-index: 2;
    backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
    /* Remove timeline and marker */
    .timeline::after,
    .timeline-marker,
    .timeline-year {
        display: none;
    }

    /* Reset container styles */
    .timeline {
        padding: 1px 10px;
        margin: 10px auto;
    }

    /* Adjust each experience item styles */
    .timeline-item {
        margin: 0 auto 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Adjust left content styles - only hide empty left content */
    .timeline-content.left:empty,
    .timeline-content.left[style*="visibility: hidden"] {
        display: none;
    }

    /* Keep left part with content */
    .timeline-content.left:not(:empty) {
        width: 90%;
        margin: 5px;
        padding: 20px;
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
    }

    .timeline-content.right:empty,
    .timeline-content.right[style*="visibility: hidden"] {
        display: none;
    }

    /* Right content styles */
    .timeline-content.right:not(:empty) {
        width: 90%;
        margin: 0;
        padding: 20px;
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
    }

    /* Content text styles */
    .timeline-content h3 {
        color: #61dafb;
        font-size: 1.3em;
        margin-bottom: 8px;
    }

    .timeline-content h4 {
        color: #ffffff;
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .timeline-content p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 0.95em;
        line-height: 1.5;
        margin-bottom: 12px;
    }

    /* Date styles */
    .timeline-date {
        display: inline-block;
        padding: 5px 12px;
        background: rgba(97, 218, 251, 0.1);
        border-radius: 15px;
        font-size: 0.9em;
    }
}